@import '../../../styles/propertySets.css';

.root {
  @apply --marketplaceModalFormRootStyles;

  justify-content: flex-start;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}
.inputWrapper {
  margin: 10px 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 20px;
  @media (--viewportMedium) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
  }
}
.inputWrapper input,
.inputWrapper label {
  font-family: var(--fontFamily);
  font-weight: var(--fontWeightSemiBold);
  font-size: 16px;
}
.iamWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 5px;
  @media (--viewportMedium) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 20px;
  }
}
.password {
  @apply --marketplaceModalPasswordMargins;
  margin-top: 0;
}

.bottomWrapper {
  @apply --marketplaceModalBottomWrapper;
}

.bottomWrapperText {
  @apply --marketplaceModalBottomWrapperText;
}

.termsText {
  @apply --marketplaceModalHelperText;
}

.termsLink {
  @apply --marketplaceModalHelperLink;
}
.termsLink:hover {
  text-decoration: underline;
  cursor: pointer;
}
